

export default function (league) {
  return [
    {
      _sort: 1,
      template: `
        <div class='avatar-layout'>
          <div class='avatar' style="background-image:url('/api/show-avatar/${league.winner.avatarId}.png')"></div>
          <div class='body'>
            <size-to-fit>
              <nobr>${league.winner.name} wins!</nobr>
            </size-to-fit>
          </div>
        </div>
      `,
    }
  ];
};
