

<template>
  <v-card class="my-6">
    <v-container>
      <h3 :class="{'red--text':!selectedAnswerId}">{{ number }}) {{ question.name }}</h3>
      <v-list>
        <v-list-item-group v-model="answerId" color="light-blue">
          <v-list-item v-for="answer in question.answers.filter(a => a.points)"
                       :key="answer._id"
                       :value="answer._id">
            <v-list-item-icon>
              <v-icon>{{ isSelected(answer) ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="answer.name + ' (' + answer.points + ')'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['question', 'user', 'number'],
  data() {
    return {
      answerId: this.selectedAnswerId,
    };
  },
  computed: {
    selectedAnswerId() {
      return this.user.guesses[this.question._id];
    },
  },
  watch: {
    answerId(newAnswerId) {
      Vue.set(this.user.guesses, this.question._id, newAnswerId);
    },
  },
  methods: {
    isSelected(answer) {
      return this.selectedAnswerId === answer._id;
    },
  },
};
</script>
