

export default function (league) {
  const PER_GROUP = 5;
  let groups = _.chain(league.rankedUsers)
    .groupBy(u => Math.floor((league.rankedUsers.indexOf(u))/PER_GROUP))
    .values()
    .value();

  if (!groups.length)
    return [];

  const lastGroup = _.last(groups);
  if (groups.length > 1 && lastGroup.length !== PER_GROUP) {
    [].push.apply(lastGroup, _.take(league.rankedUsers, PER_GROUP-lastGroup.length));
  }

  const chronologicallyNextQuestions = _.take(league.unansweredQuestions, 3);
  const queuedNextQuestions = _.filter(league.unansweredQuestions, {next:true});
  const nextQuestions = queuedNextQuestions.length ? queuedNextQuestions : chronologicallyNextQuestions;
  const questions = _.take(nextQuestions, 2);

  return groups.map(group => ({
    _sort: 100,
    template: `
      <div style="z-index: 100; position: relative;">
        <table class="upcoming-answers-table" style="margin-top: 10px; border-collapse: collapse;">
          <thead>
            <tr>
              <th colspan="2" style="font-size: 0.5em; color:#666; text-align: center;">
                NEXT UP»
              </th>
              <th v-for="q of questions" style="font-size:0.5em; text-align: left; line-height: 1em; padding-bottom: 4px;">
                {{ q.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u of users" style="border-top:solid 1px #555;">
              <td style="overflow: hidden; text-overflow: ellipsis;">
                <div style="width:1.2em; height: 1.2em; position: relative; overflow: hidden;">
                  <img :src="'/api/show-avatar/'+u.avatarId+'.png'" style="width:2.4em; position: absolute; top:0; right:0;" />
                </div>
              </td>
              <td style="color:#ccc; text-transform: uppercase; font-size:0.5em; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">#{{ u.rank }} {{ u.name }}</td>
              <td v-for="q of questions" style="font-size: 0.5em;">
                <span>•</span>
                {{ u.guesses[q._id] ? q.answers.find(a => a._id === u.guesses[q._id]).name : '-' }}
                <span style="color:#3af; font-size: 0.7em;">
                  {{ u.guesses[q._id] ? (q.answers.find(a => a._id === u.guesses[q._id]).points.toLocaleString() + ' Points') : '' }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      `,
    data: () => {
      return {
        users: group,
        questions,
      };
    },
  }));
};
