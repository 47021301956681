

export default function (league) {
  const usersByPotential = _.sortBy(league.rankedUsers, u => -u.potential)
    .filter(u => !u.ghost);

  return [
    {
      _sort: 100,
      template: `
        <div class='full-layout'>
          <size-to-fit>
            <h1>Best Case Scenario</h1>
            <p style="color:#aaa; line-height: 1em;">If you got the rest of the questions correct, your score would be:</p>
            <p>${ usersByPotential.map(u => u.name + ' ' + (u.potential || 0).toLocaleString()).join(', ') }</p>
          </size-to-fit>
        </div>
        `,
    }
  ]
};
