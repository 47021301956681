

<template>
  <div style="text-align:left; break-inside: avoid-column;">
    <div>{{ league.questions.indexOf(question) + 1 }}) {{ question.name }}</div>
    <table style="width:100%;">
      <tr v-for="answer in question.answers">
        <td style="width:40%; vertical-align: top;">
          • {{ answer.name }}

          <span style="color:#6cf; font-size: 0.7em;">({{ answer.points }} Points)</span>
          <span style="font-size:0.7em; color:#999;">{{ answer.guessers.map(u => u.name).join(', ') || 'no one' }}</span>
        </td>
      </tr>
    </table>
    <br />
  </div>
</template>

<script>
export default {
  props: ['league', 'question']
}
</script>