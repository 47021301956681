
module.exports = function (league) {
  if (league.winner) {
    return `${league.winner.name} wins with ${league.winner.score} points!`;
  }
  const usersSorted = _.sortBy(league.users, u => u.name.trim().toLowerCase());
  return _.chain(league.unansweredQuestions)
    .take(3)
    .map(q => {
      const segments = [];
      segments.push(`<span class='question'>${q.name}</span> &nbsp; `);
      for (const a of q.answers) {
        if (!a.points) continue;
        segments.push(`<span class="answer"> &nbsp; ☐ ${a.name}</span>`);
        segments.push(`<span class="points">(${(a.points || 0).toLocaleString()} points)</span>`);
        const playerNames = usersSorted.filter(u => {
          return u.guesses[q._id] === a._id;
        }).map(u => `<span class="player-name">${u.name}</span>`).join('') || `<span class="nobody">Nobody</span>`;
        segments.push(`<span class="players">${playerNames}</span>`);
      }
      return segments.join(' ');
    })
    .value()
    .join(' &nbsp; <span class="separator">★★★★★</span> &nbsp; ');
};
