
import NewLeaguePage from 'src/new-league/page.vue';
import LeaguesPage from 'src/leagues/page.vue';
import ManagePage from 'src/manage/page.vue';
import UserPage from 'src/user/page.vue';
import AnswersPage from 'src/answers/page.vue';
import ScoreboardPage from 'src/scoreboard/page.vue';
import QuestionsPage from 'src/questions/page.vue';

export default [
  { path: '/', redirect: '/new-league' },
  { path: '/leagues', component: LeaguesPage },
  { path: '/new-league', component: NewLeaguePage },
  { path: '/manage/:id', component: ManagePage },
  { path: '/register/:leagueId', component: UserPage },
  { path: '/user/:userId', component: UserPage },
  { path: '/user/:userId/:ignore', component: UserPage },
  { path: '/answers/:id', component: AnswersPage },
  { path: '/questions/:id', component: QuestionsPage },
  { path: '/scoreboard/:id', component: ScoreboardPage, meta: { layout: 'blank' } },
];
