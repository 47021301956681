

export default function (league) {
  let leaders = _.chain(league.rankedUsers)
    .filter(u => u.rank === 1)
    .filter(u => u.score > 0)
    .value();

  if (!leaders.length || leaders.length > 3)
    return [];

  return _.map(leaders, user => {
    return {
      _sort: 100,
      template: `
      <div class='avatar-layout'>
        <div class='avatar' style="background-image:url('/api/show-avatar/${user.avatarId}.png')"></div>
        <div class='body'>
          <size-to-fit>
            <br />
            <h1>${user.name} ${leaders.length === 1 ? 'is in 1st Place!' : 'is tied for 1st!'}</h1>
            <p>
                ${user.name} is in the lead with ${(user.score||0).toLocaleString()} points 
                and ${user.numberCorrect} correct out of ${league.answeredQuestions.length}.
            </p>
            <br />
          </size-to-fit>
        </div>
      </div>
      `,
    };
  });
};
