"use strict";
exports.__esModule = true;
require("./main.less");
exports["default"] = {
    computed: {
        layout: function () {
            return (this.$route.meta.layout || 'default') + '-layout';
        },
    },
    components: {
        'default-layout': { template: '<v-app><router-view></router-view></v-app>' },
        'blank-layout': { template: '<router-view></router-view>' },
    },
};
