
<style lang="less" scoped>
  .correct {
    color:#3d6;
  }
  .incorrect {
    color:#c33;
  }
</style>

<template>
  <div class="full-layout">
    <size-to-fit>
      <div style="text-align:left;">
        <div style="font-size:0.5em; color:#999;">JUST ANSWERED...</div>
        <h3>{{ question.name }}</h3>
        <div v-for="answer in question.answers" :class="{correct:answer.correct,incorrect:!answer.correct}" style="line-height: 0.8em; margin:0.2em 0;">
          • {{ answer.name }}
          <span style="font-size:0.7em;">
            <span v-if="answer.correct">
              ({{ (answer.points || 0).toLocaleString() }} points for
              {{ answer.guessers.map(u => u.name).join(', ') || 'nobody' }})
            </span>
            <span v-else>
              ({{ answer.guessers.map(u => u.name).join(', ') || 'nobody' }})
            </span>
          </span>
        </div>
      </div>
    </size-to-fit>
  </div>
</template>

<script>
export default {
  props: ['league', 'question']
}
</script>