
<template>
  <div class="ticker">
    <transition name="fade" mode="out-in">
      <marquee-component :key="html" v-html="html"></marquee-component>
    </transition>
  </div>
</template>

<script>
import './ticker.less';
const getHtml = require("./get-html");

export default {
  props: ['league'],
  computed: {
    html() {
      return getHtml(this.league);
    },
  },
  components: {
    'marquee-component': require("./marquee.vue").default,
  },
};
</script>
