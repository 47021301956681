

export default function (league) {
  let users = _.sortBy(league.rankedUsers, u => u.gamePotential);

  if (!users.length)
    return [];

  users = users
    .filter(u => u.gamePotential > 0)
    .filter(u => u.gamePotential === users[0].gamePotential);

  users = _.take(users, 2);

  return _.map(users, user => {
    return {
      _sort: 100,
      template: `
      <div class='avatar-layout'>
        <div class='avatar' style="background-image:url('/api/show-avatar/${user.avatarId}.png')"></div>
        <div class='body'>
          <size-to-fit>
            <br />
            <h1>${user.name} - Plays it Safe!</h1>
            <p>
                ${user.name} took the least number of risks... potentially scoring ${(user.gamePotential||0).toLocaleString()} points!
            </p>
            <p>
                ${user.name} is currently ranked ${user.rank} with ${(user.score||0).toLocaleString()} points and ${user.numberCorrect}/${league.answeredQuestions.length} correct answers.
            </p>
            <br />
          </size-to-fit>
        </div>
      </div>
      `,
    };
  });
};
