

const Slide = require("./slide.vue").default;

export default function (league) {
  const chronologicallyNextQuestions = _.take(league.unansweredQuestions, 3);
  const queuedNextQuestions = _.filter(league.unansweredQuestions, {next:true});
  const nextQuestions = queuedNextQuestions.length ? queuedNextQuestions : chronologicallyNextQuestions;

  return _.map(nextQuestions, (question, i) => {
    return {
      _sort: 20 + i,
      template: '<slide v-bind="model"></slide>',
      data: () => ({ model: { league, question } }),
      components: {
        slide: Slide
      },
    };
  });
};
