

<template>
  <div class="full-layout">
    <size-to-fit>
      <div style="text-align:left;">
        <div style="font-size:0.5em; color:#999;">NEXT UP...</div>
        <h3>{{ question.name }}</h3>
        <div v-for="answer in question.answers" style="line-height: 0.8em; margin:0.2em 0;">
          • {{ answer.name }}
          <span style="font-size:0.7em;">
          <span style="color:#6cf;">({{ (answer.points||0).toLocaleString() }} Points)</span>
          <span style="color:#999;">{{ answer.guessers.map(u => u.name).join(', ') || 'no one' }}</span>
        </span>
        </div>
      </div>
    </size-to-fit>
  </div>
</template>

<script>
export default {
  props: ['league', 'question']
}
</script>