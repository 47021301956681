
<style src="./avatar.less" scoped></style>

<template>
  <div>

    <div v-if="isLoading">
      <v-progress-circular indeterminate />
    </div>
    <div v-else-if="user.avatarId" style="margin:12px 0;">
      <img :src="`/api/show-avatar/${user.avatarId}`" style="width: 200px; vertical-align: middle;" />
      <v-btn v-if="user.avatarId.length > 10" @click="flip" x-small class="ml-2"><v-icon small>mdi-arrow-expand-horizontal</v-icon> FLIP</v-btn>
    </div>

    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="Create">Create an Avatar <span style="color:#00cc66; font-size: 0.8em; font-weight: bold; position: relative; top:-5px; left: 4px;">BETA</span></v-tab>
      <v-tab key="Library">Choose from Library</v-tab>
      <v-tab>Upload an image</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-6">
      <v-tab-item>
        <v-card class="pa-8">
          <create-avatar :user="user" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="pa-8">
        <img v-for="id in availableIds"
             :key="id"
             :src="`/api/show-avatar/${id}.png`"
             class="avatar"
             :class="{active: isSelected(id)}"
             @click="select(id)"
        />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <input type="file" @change="onFileChange" accept="image/*" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import axios from 'axios';

const _ = require('lodash');
// Superbowl
const superbowlAvatarIds = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,27,25,26,50,51,52,53,54,55,56,57,58,59,60,61,62,63,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,pooh'.split(',');
const oscarsAvatarIds = '300,301,302,303,304,305,306,307,308,309'.split(',');
const avatarIds = _.flatten([oscarsAvatarIds, superbowlAvatarIds])
    .filter(id => !['214', '61'].includes(id));

export default {
  props: ['league', 'user'],
  data() {
    return {
      tab: 0,
      availableIds: avatarIds,
      isLoading: false,
    };
  },
  methods: {
    isSelected(id) {
      return this.user.avatarId === id;
    },
    select(id) {
      Vue.set(this.user, 'avatarId', id);
    },
    async flip() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        if (!this.user.avatarId) return;
        const response = await axios.post('/api/flip-avatar', { avatarId: this.user.avatarId });
        if (response.data) this.user.avatarId = response.data;
      } catch (e) {
        const msg = (e.response && e.response.data) || e;
        alert(String(msg));
      } finally {
        this.isLoading = false;
      }
    },
    async onFileChange(e) {
      if (this.isLoading) return;
      const files = (e.target).files;
      if (!files || files.length === 0) return;
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64 = reader.result.split(',')[1];
        try {
          this.isLoading = true;
          const response = await axios.post('/api/upload-avatar', { base64 });
          this.user.avatarId = response.data;
        } catch (e) {
          const msg = (e.response && e.response.data) || e;
          alert(String(msg));
        } finally {
          this.isLoading = false;
        }
      };
      reader.readAsDataURL(file);
    },
  },
  components: { CreateAvatar: require('./create-avatar.vue').default },
};
</script>
