

export default function (league) {
  if (league.answeredQuestions.length === 0)
    return [];

  let groups = _.chain(league.rankedUsers)
    // .filter(u => u.score > 0)
    .groupBy(u => Math.floor((league.rankedUsers.indexOf(u))/5))
    .values()
    .value();

  if (!groups.length)
    return [];

  const lastGroup = _.last(groups);
  if (groups.length > 1 && lastGroup.length !== 5) {
    [].push.apply(lastGroup, _.take(league.rankedUsers, 5-lastGroup.length));
  }

  return groups.map(group => ({
    _sort: 100,
    template: `
      <div>
        <div v-for="(u, i) of users" style="text-align: center; width:170px; position: absolute; top:5px;" :style="{left: -10 + (i*150)+'px', 'z-index': 100-i}">
          <div style="width: 100%; font-size:19px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ u.name.toUpperCase() }}</div>
          <div style="margin-top:-3px; font-size:12px; color:#3cf; text-transform: uppercase;">#{{ u.rank}}<span style="color:#555; font-size: 8px; position: relative; top:-2px; padding:0 2px;">★</span>{{ (u.score || 0).toLocaleString() }} Points</div>
          <img :src="'/api/show-avatar/'+u.avatarId+'.png'" style="position:absolute; width:320px; top: 34px; right:0;" />
        </div>
      </div>
      `,
    data: () => {
      return {
        users: group,
      };
    },
  }));
};
