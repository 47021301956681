
<style src="./size-to-fit.less" scoped></style>

<template>
  <div :class="{'fit-to-parent':1,show:!hidden}">
    <slot />
  </div>
</template>

<script>
const monitorResize = require("./monitor-resize");
const monitorMutation = require("./monitor-mutation");
const resizeText = require("./resize-text");

export default {
  props: {
    min: { default: 0.1, type: Number },
    max: { default: 4, type: Number },
  },
  data() {
    return { hidden: false };
  },
  created() {
    this.editingStarted = () => {
      //this.hidden = true;
    };

    this.editingEnded = _.debounce(() => {
      this.calculate();
      this.hidden = false;
    }, 50, { leading: false, trailing: true });
  },
  methods: {
    calculate() {
      resizeText(this.$el, this.min, this.max);
    },
    somethingChanged() {
      this.editingStarted();
      this.editingEnded();
    },
  },
  mounted() {
    this.calculate();
    this.resizeMonitor = monitorResize(() => this.somethingChanged());
    this.mutationMonitor = monitorMutation(this.$el, () => this.somethingChanged());
  },
  beforeDestroy: function () {
    this.mutationMonitor.disconnect();
    this.resizeMonitor.disconnect();
  },
};
</script>
