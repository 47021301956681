
<template>
  <v-container v-if="ready" style="max-width:900px;">


    <v-row>
      <v-col>


        <div>
          <v-text-field
              v-model="league.name"
              label="League Name"
              placeholder="League Name"
              outlined
              hide-details
              class="my-2"
          />
          <v-btn v-if="league.name && league.name !== origLeagueName" small @click="saveName">Save ></v-btn>
          <v-snackbar v-model="showSaveSnackbar" :timeout="3000">
            Changes have been saved.
          </v-snackbar>
        </div>


        <v-timeline dense>
          <v-timeline-item right fill-dot light large color="white">
            <template v-slot:icon>1</template>
            <div class="mt-3">
              <strong>Send Invites</strong><br />
              Send everyone this link so they can create their team:<br />
              <div class="d-flex flex-row">
                <input style="flex: 1; box-sizing: border-box; width:100%;; color:#09f; padding:2px; border:solid 1px #ccc; outline: none;" type="text" :value="'https://props.allstarapps.com/register/'+league._id" @focus="$event.target.select()" />
                <v-btn small style="min-width: 0; padding: 0 10px;" :href="'https://props.allstarapps.com/register/'+league._id"><v-icon small>mdi-magnify</v-icon></v-btn>
              </div>
            </div>
          </v-timeline-item>
          <v-timeline-item right fill-dot light large color="white">
            <template v-slot:icon>2</template>
            <div class="mt-3">
              <strong>See the Scoreboard</strong><br />
              See all the teams here:
              <br />
              <div class="d-flex flex-row">
                <input style="flex: 1; box-sizing: border-box; color:#09f; width:100%; padding:2px; border:solid 1px #ccc; outline: none;" type="text" :value="'https://props.allstarapps.com/scoreboard/'+league._id" @focus="$event.target.select()" />
                <v-btn small style="min-width: 0; padding: 0 10px;" :href="'https://props.allstarapps.com/scoreboard/'+league._id"><v-icon small>mdi-magnify</v-icon></v-btn>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>


    <hr class="my-8" />

    <h2>
      <v-btn small style="float:right;" :href="'/register/'+league._id">+ New Player</v-btn>
      {{ league.users.length }} Players
      <span style="font-weight: normal; font-size:12px;">(Recommended 25 MAX)</span>
    </h2>


    <br />
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left"></th>
          <th class="text-left">Player</th>
          <th class="text-left">Answers</th>
          <th class="text-left">Created</th>
          <th class="text-left">Does someone want to change their answers, avatar, or name? Send them this link</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in _.sortBy(league.users, u => u.name.toLowerCase())" :key="user._id">
          <td>
            <v-btn small :href="'/user/'+user._id+'/'+user.name.toLowerCase().replace(/[^a-z0-9]/ig, '')">
              Edit
            </v-btn>
          </td>
          <td>{{ user.name }}</td>
          <td>
            <span v-if="Object.keys(user.guesses).length === league.questions.length">
              <v-chip small color="success">Complete</v-chip>
            </span>
            <span v-else>
              <v-chip small color="warning">Incomplete</v-chip>
            </span>
          </td>
          <td style="font-size:10px; color:#aaa;">
            {{ new Date(parseInt(user._id.substring(0, 8), 16) * 1000).toLocaleTimeString() }}
            {{ new Date(parseInt(user._id.substring(0, 8), 16) * 1000).toLocaleDateString() }}
          </td>
          <td>
            <input style="box-sizing: border-box; width:100%; padding:2px; border:solid 1px #ccc; outline: none;" type="text" :value="'https://props.allstarapps.com/user/'+user._id+'/'+user.name.toLowerCase().replace(/[^a-z0-9]/ig, '')" @focus="$event.target.select()" />
          </td>
          <td>
            <v-btn outlined color="gray" icon small @click="deleteUser(user)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <br />
    <br />
    <br />

  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  async created() {
    const leagueId = this.$route.params.id;
    this.league = (await axios.get(`/api/manage-get-league/${leagueId}`)).data;
    this.origLeagueName = this.league.name;
    this.ready = true;
  },
  data() {
    return {
      ready: false,
      showSaveSnackbar: false,
      league: null,
      origLeagueName: '',
    };
  },
  methods: {
    async saveName() {
      try {
        await axios.post('/api/manage-set-league-name', { name: this.league.name, id: this.league._id });
        this.showSaveSnackbar = true;
      } catch (e) {
        const msg = (e.response && e.response.data) || e;
        alert(msg);
      }
    },
    async deleteUser(user) {
      try {
        if (!confirm(`Are you sure you want to delete ${user.name}?`))
          return;

        await axios.post('/api/manage-delete-user', { userId: user._id, leagueId: this.league._id });
        location.reload();
      } catch (e) {
        const msg = (e.response && e.response.data) || e;
        alert(msg);
      }
    },
  },
};
</script>
