
var callbacks = [];

$(window).on('resize', function(){
  _.each(callbacks, function(callback){
    callback();
  });
});

module.exports = function (callback) {
  callbacks.push(callback);

  return {
    disconnect: function () {
      var idx = callbacks.indexOf(callback);
      if (idx !== -1) callbacks.splice(idx, 1);
    }
  };
};