

const Slide = require("./slide.vue").default;

export default function (league) {
  const recentlyAnsweredQuestions = _.chain(league.answeredQuestions)
    .sortBy(q => -q.answeredTime)
    .filter(q => q.answeredTime > (Date.now() - 1000*60*2))
    .take(3)
    .value();

  const lastAnsweredQuestions = _.chain(league.answeredQuestions)
    .sortBy(q => -q.answeredTime)
    .take(1)
    .value();

  const questions = recentlyAnsweredQuestions.length ? recentlyAnsweredQuestions : lastAnsweredQuestions;

  return _.map(questions, question => {
    return {
      _sort: recentlyAnsweredQuestions.length ? 10 : 20,
      template: '<slide v-bind="model"></slide>',
      data: () => ({ model: { league, question } }),
      components: {
        slide: Slide
      },
    };
  });
};
