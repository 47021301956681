
<template>
  <v-container v-if="ready" style="max-width:900px;">
    <v-app-bar app>
      <v-toolbar-title>Trivia Game</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <h1>Create a League</h1>
    <br />
    <br />

    <v-alert v-if="manageLeagueUrl" color="success">
      <div style="color:#FFF;">Go here to invite players and manage your league: <strong>(SAVE THIS LINK)</strong></div>
      <input style="box-sizing: border-box; width:100%; padding:8px; border-radius: 5px; border:solid 2px #FFF; outline: none; background-color: #fff;" type="text" :value="manageLeagueUrl" @focus="$event.target.select()" />

    </v-alert>

    <div v-else>
      <v-text-field
          v-model="name"
          label="Create a League"
          placeholder="e.g. Josh's Super Bowl Party"
          outlined
          hide-details
          class="my-2"
      />
      <v-btn v-show="!savingNewLeague" color="primary" @click="addLeague">Create My League ></v-btn>
    </div>
    <br />
    <br />
    <hr />
    <br />
    <br />

    <v-row>
      <v-col>
        <h3>How It Works</h3>
        <ul>
          <li>It's free</li>
          <li>Invite 2-25 players</li>
          <li>Great for ages 2 and up</li>
          <li>Each player answers {{ questions.length }} questions -></li>
          <li>Watch the scoreboard while you watch the Super Bowl&reg;</li>
          <li>Earn point for correct answers</li>
          <li>Everyone can watch the scoreboard from their own home</li>
          <li>The game ends at half time (so the little ones can go to bed)</li>
        </ul>

        <br />
        <img style="width:100%; max-width: 500px;" src="../../public/marketing/scoreboard-ss.jpg" />
      </v-col>
      <v-col>
        <h3>Preview of the Questions</h3>
        <ol>
          <li v-for="q of questions" :key="q._id">
            <strong>{{ q.name }}</strong><br />
            <div v-for="a of q.answers" v-if="a.points" :key="a._id">• {{ a.name }}</div>
          </li>
        </ol>
      </v-col>
    </v-row>
    <br />
    <br />

  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  async created() {
    this.questions = (await axios.get(`/api/new-league-model`)).data.questions;
    this.ready = true;
  },
  data() {
    return {
      name: '',
      savingNewLeague: false,
      ready: false,
      leagueId: null,
      questions: null,
    };
  },
  computed: {
    manageLeagueUrl() {
      if (!this.leagueId) return;
      return `https://props.allstarapps.com/manage/${this.leagueId}`;
    },
  },
  methods: {
    async addLeague() {
      try {
        this.savingNewLeague = true;
        if (!this.name) throw 'Please enter a league name';
        const response = await axios.post('/api/add-league', { name: this.name });
        this.leagueId = response.data.leagueId;
      } catch (e) {
        const msg = (e.response && e.response.data) || e;
        alert(msg);
        this.savingNewLeague = false;
      }
    },
  }
};
</script>
