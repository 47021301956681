

export default function (league) {
  let limbs = [];

  for (const user of league.rankedUsers) {
    const otherUsers = league.rankedUsers.filter(u => u._id !== user._id);
    const limbAnswerId = _.find(user.answerIds, answerId => {
      return !_.find(otherUsers, otherUser => {
        return otherUser.answerIds.indexOf(answerId) > -1;
      });
    });
    if (!limbAnswerId) continue;
    const question = _.find(league.questions, q => {
      return _.find(q.answers, a => a._id === limbAnswerId);
    });
    const answer = _.find(question.answers, a => a._id === limbAnswerId);
    limbs.push({ user, question, answer });
  }

  return _.map(limbs, ({ user, question, answer }) => {
    return {
      _sort: 100,
      template: `
      <div class='avatar-layout'>
        <div class='avatar' style="background-image:url('/api/show-avatar/${user.avatarId}.png')"></div>
        <div class='body'>
          <size-to-fit>
            <br />
            <h1>${user.name} went out on a limb!</h1>
            <p>
                ${user.name} selected an answer that no one else did...
            </p>
            <br />
            <p>
                ${question.name} 
                <span style="color:#3ad;">
                - ${answer.name} for ${(answer.points||0).toLocaleString()} points.
                </span>
            </p>
            <br />
          </size-to-fit>
        </div>
      </div>
      `,
    };
  });
};
