"use strict";
exports.__esModule = true;
var vue_router_1 = require("vue-router");
var composition_api_1 = require("@vue/composition-api");
var root_vue_1 = require("src/root/root.vue");
var size_to_fit_vue_1 = require("src/components/size-to-fit/size-to-fit.vue");
var client_routes_js_1 = require("./client-routes.js");
var _ = require('lodash');
var Vue = require('vue');
var Vuetify = require('vuetify');
Vue.config.productionTip = false;
Vue.prototype._ = _;
Vue.use(Vuetify);
Vue.use(composition_api_1["default"]);
Vue.use(vue_router_1["default"]);
Vue.component('size-to-fit', size_to_fit_vue_1["default"]);
var router = new vue_router_1["default"]({
    mode: 'history',
    routes: client_routes_js_1["default"],
});
new Vue({
    el: '#root',
    router: router,
    // @ts-ignore
    vuetify: new Vuetify({}),
    render: function (h) { return h(root_vue_1["default"]); },
});
