

<template>
  <v-card class="my-6">
    <v-container>
      <h3 :class="{'red--text':!selectedAnswerId}">
        {{ number }}) {{ question.name }}
        <v-btn @click="question.next = !question.next">
          <input type="checkbox" :checked="question.next" /> &nbsp; Next?
        </v-btn>
      </h3>
      <v-list>
        <v-list-item-group v-model="answerId" color="light-blue">
          <v-list-item v-for="answer in question.answers"
                       :key="answer._id"
                       :value="answer._id">
            <v-list-item-icon>
              <v-icon>{{ isSelected(answer) ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="answer.name + ' (' + answer.points + ')'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-btn color="primary" @click="$emit('save')">
        <v-icon left>mdi-content-save-outline</v-icon> Save All
      </v-btn>

    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['question', 'answers', 'number'],
  data() {
    return {
      answerId: this.selectedAnswerId,
    };
  },
  computed: {
    selectedAnswerId() {
      return this.answers[this.question._id];
    },
  },
  watch: {
    answerId(newAnswerId) {
      this.question.next = false;
      Vue.set(this.answers, this.question._id, newAnswerId);
    },
  },
  methods: {
    isSelected(answer) {
      return this.selectedAnswerId === answer._id;
    },
  },
};
</script>
