

export default function (league) {
  const usersToShow = _.chain(league.rankedUsers)
    .filter(u => !u.ghost)
    .sortBy(u => Math.random())
    .take(5)
    .value();

  if (usersToShow.length < 3) return [];

  return _.map(usersToShow, user => {
    return {
      _sort: 100,
      data: () => {
        let others = league.rankedUsers.filter(u => u._id !== user._id && !u.ghost).map(u => {
          return {
            number: user.answerIds.filter(answerId => u.answerIds.includes(answerId)).length,
            u,
          };
        });
        others = _.sortBy(others, o => -o.number);
        others = _.take(others, 5);
        others[0].first = true;

        return {
          user,
          others,
        };
      },
      template: `
      <div class='avatar-layout'>
        <div class='avatar' style="background-image:url('/api/show-avatar/${user.avatarId}.png')"></div>
        <div class='body'>
          <size-to-fit>
            <h1 style="text-transform: uppercase; white-space: nowrap; margin-top:5px;">${user.name}'s Matchup</h1>
            <br />
            <p v-html=" _.chain(others)
                    .map(({u, number, first}) => {
                      return number + (first ? ' answers' : '') + ' like ' + u.name;
                    })
                    .value()
                    .join('<br />')
                ">
            </p>
          </size-to-fit>
        </div>
      </div>
      `,
    };
  });
};
