

<template>
  <div class="player" :class="{beginning:isBeginning, 'floating-player':['215','61'].includes(user.avatarId) }">
    <div v-if="user.avatarId === '222'" style="width:0.5px; height:2000px; position: absolute; bottom:78px; left: -31.5px; background-color:rgba(255,255,255,1);"></div>
    <img v-if="user.fire" class="fire" :src="'/avatars/fire.png'" />
    <img class="avatar" :src="'/api/show-avatar/'+(user.avatarId||('ghost-'+league.type))+'.png'" />
    <div class="name" :style="{color:user.answerIds.length?'#fff':'#f66'}">
      {{ user.name}}<span v-if="!isBeginning">: {{ (user.score||0).toLocaleString() }}</span>
    </div>
  </div>
</template>


<script>
  export default {
    props: ['league', 'user'],
    mounted() {
      this.updateStyle(false);
    },
    computed: {
      isBeginning () {
        return this.league.answeredQuestions.length === 0;
      },
      playerStyle () {
        const user = this.user;
        let yProgress = (1 - (this.league.rankedUsers.indexOf(user) / (this.league.rankedUsers.length - 1))) || 0;
        let xProgress = user.progress || 0;

        if (this.isBeginning) {
          const perc = ((this.league.rankedUsers.indexOf(user) / (this.league.rankedUsers.length - 1))) || 0;
          xProgress = 0.05 + perc * 0.95;
          yProgress = Math.min(0.98, Math.max(0.02, 1 - Math.abs((perc * 2) - 1)));
        }

        const tl = { top: 46.2, left: 20.1 };
        const tr = { top: 46.2, left: 78.9 };
        const br = { top: 85, left: 96.2 };
        const bl = { top: 85, left: 3.3 };

        const top = bl.top + (tl.top - bl.top) * yProgress;
        const leftForTop = tl.left + (tr.left - tl.left) * xProgress;
        const leftForBottom = bl.left + (br.left - bl.left) * xProgress;
        const left = leftForBottom + (leftForTop - leftForBottom) * yProgress;
        // const scale = 1.2 - (yProgress * 0.05); // superbowl
        const scale = 1.4 - (yProgress * 0.05); // oscars

        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `scale(${scale})`,
          'z-index': Math.round(top),
        };
      }
    },
    watch: {
      playerStyle () {
        this.updateStyle(true);
      }
    },
    methods: {
      updateStyle (animate) {
        $(this.$el)[animate ? 'animate' : 'css'](this.playerStyle, 2000);
      }
    }
  };
</script>
