

export default function (league) {
  const questions = _.sortBy(league.answeredQuestions, q => -q.answeredTime);

  return _.chain(league.users)
    .filter(u => u.fire)
    .map(user => {
      let inARow = 0;
      for (const q of questions) {
        if (q.correctAnswer._id === user.guesses[q._id]) {
          inARow += 1;
        }else{
          break;
        }
      }

      return {
        _sort: 100,
        template: `
        <div class='avatar-layout'>
          <div class='avatar' style="background-image:url('/api/show-avatar/${user.avatarId}.png')"></div>
          <div class='body'>
            <size-to-fit>
              <h1>${user.name} is on fire!</h1>
              <br />
              <div>Call the fire department!</div>
              <div>
                ${user.name} is currently ranked ${user.rank} and has gotten the last ${inARow} questions correct!
              </div>
              <br />
            </size-to-fit>
          </div>
        </div>
        `,
      };
    })
    .value();
};
