
import generateSlides from "./slides/generate";

export default {
  props: ['league'],
  data() {
    return {
      count: null,
      slides: [],
      slideIndex: 0,
      slideInterval: null,
    };
  },
  watch:{
    league: function (){
      this.startSlides();
    },
  },
  methods: {
    countdown (callback) {
      this.count = 4;
      const interval = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) {
          clearInterval(interval);
          this.count = null;
          callback();
        }
      }, 1000);
    },
    startSlides() {
      if (!this.league) return;

      this.slideIndex = 0;
      clearInterval(this.slideInterval);

      this.slides = generateSlides(this.league);
      this.slideInterval = setInterval(() => {
        this.slideIndex += 1;
        if (this.slideIndex >= this.slides.length)
          this.slideIndex = 0;
      }, 12000);
    },
  },
};
