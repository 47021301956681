
import winner from "./winner/generate";
import answeredQuestions from "./answered-questions/generate";
import upcomingAnswers from "./upcoming-answers/generate";
import nextQuestions from "./next-questions/generate";
import bestCase from "./best-case/generate";
import fire from "./fire/generate";
import riskTaker from "./risk-taker/generate";
import safe from "./safe/generate";
import leaders from "./leaders/generate";
import topLeaders from "./top-leaders/generate";
import limb from "./limb/generate";
import compareAnswers from "./compare-answers/generate";

export default function (league) {
  if (league.winner) {
    return [
      ...winner(league),
    ];
  }

  if (league.questions.find(q => q.next)) {
    return nextQuestions(league);
  }

  let slides = [
    ...compareAnswers(league),
    ...limb(league),
    ...leaders(league),
    ...topLeaders(league),
    ...upcomingAnswers(league),
    ...safe(league),
    ...riskTaker(league),
    ...bestCase(league),
    ...answeredQuestions(league),
    ...nextQuestions(league),
    ...fire(league),
  ];

  slides = _.sortBy(slides, () => Math.random());

  return _.sortBy(slides, '_sort');
};
