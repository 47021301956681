
<style scoped lang="less">
  .marquee {
    position: absolute;
    overflow: visible;
    display:none;
    white-space: nowrap;
  }
</style>

<template>
  <div class="marquee">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    const $el = $(this.$el);

    const restart = () => {
      if (this.dead) return;
      $el.hide();
      $el.css("left", "0");
      $el.fadeIn(2000, () => {
        const width = $el.width() - $el.parent().width();
        let newStyle = {};
        let ms = 5000;
        if (width > 0) {
          newStyle = { left: `${-1 * width}px` };
          ms = width * 10;
        }
        $el.animate(newStyle, ms, "linear", () => {
          setTimeout(() => {
            $el.fadeOut(2000, () => restart());
          }, 2000);
        });
      });
    };

    restart();
  },
  beforeDestroy() {
    this.dead = true;
  },
};
</script>
