

module.exports = function (el, callback) {

  var observer = null;

  if ('MutationObserver' in window) {
    observer = new MutationObserver(function(){
      callback();
    });

    observer.observe(el, {
      //attributes: true, //creates an endless loop because *we* change attributes
      childList: true,
      subtree: true,
      characterData: true
    });
  }

  return {
    disconnect: function () {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    }
  };
};