
<template>
  <div>
    <div>Describe your character and AI will create an image for you...</div>
    <div v-if="error" style="margin:8px 0; color:#f00;">
      Yeah, sorry, it breaks sometimes. Give it another shot. (error: {{error}})
    </div>
    <div>
      <textarea :disabled="isLoading" :readonly="isLoading" :style="{opacity: isLoading ? '0.5' : '1'}" v-model="q" placeholder="e.g. A fat giraffe with a red bow tie wearing roller skates" style="width: 100%; border: solid 1px #999; padding:5px;"></textarea>
    </div>
    <div v-if="isLoading">
      <v-progress-circular indeterminate />
      <span style="color:#ccc;">(hold tight, this takes about 10-15 seconds)</span>
    </div>
    <v-btn v-else @click="createImage" color="primary">Preview ></v-btn>
  </div>
</template>

<script>
import axios from 'axios';

const _ = require('lodash');

export default {
  props: [ 'user'],
  data() {
    return {
      imageId: '',
      isLoading: false,
      q: '',
      error: '',
    };
  },
  methods: {
    async createImage(){
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        this.imageId = null;
        this.error = null;
        const response = await axios.post('/api/create-avatar', { q: this.q });
        this.imageId = response.data;
        this.user.avatarId = this.imageId;
      } catch (e) {
        this.imageId = null;
        const msg = (e.response && e.response.data) || e;
        this.error = String(msg);
      }finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
