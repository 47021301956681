
<template>
  <v-container v-if="ready" style="max-width:900px;">

    <h1>Leagues</h1>
    <br />
    <br />

    <div>
      <v-text-field
          v-model="name"
          label="Add a League"
          placeholder="League Name"
          outlined
          hide-details
          class="my-2"
      />
      <v-btn v-show="!savingNewLeague" small @click="addLeague">Create ></v-btn>
    </div>
    <br />

    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left"></th>
          <th class="text-left">Name</th>
          <th class="text-left">Players</th>
          <th class="text-left">Created</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="league in _.sortBy(leagues, l => (l.name || '').toLowerCase())" :key="league._id">
          <td>
            <v-btn small :href="'/manage/'+league._id">
              Manage
            </v-btn>
          </td>
          <td>{{ league.name || '-' }}</td>
          <td>{{ league.users.length }}</td>
          <td style="font-size:10px; color:#aaa;">
            {{ new Date(parseInt(league._id.substring(0, 8), 16) * 1000).toLocaleTimeString() }}
            {{ new Date(parseInt(league._id.substring(0, 8), 16) * 1000).toLocaleDateString() }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <br />
    <br />
    <br />

  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  async created() {
    this.leagues = (await axios.get(`/api/leagues`)).data.leagues;
    this.ready = true;
  },
  data() {
    return {
      name: '',
      savingNewLeague: false,
      ready: false,
      leagues: null,
    };
  },
  methods: {
    async addLeague() {
      try {
        this.savingNewLeague = true;
        const response = await axios.post('/api/add-league', { name: this.name });
        location.href = `/manage/${response.data.leagueId}`;
      } catch (e) {
        const msg = (e.response && e.response.data) || e;
        alert(msg);
        this.savingNewLeague = false;
      }
    },
  }
};
</script>
