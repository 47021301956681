
module.exports = function resizeText(el, min, max) {
  var origMin = min;
  var origMax = max;

  // binary search
  while (true) {
    /*
     * On Windows Chrome, when an element first loads, text won't always
     * line-break. Instead, the `scrollWidth` expands past the width of
     * the parent. This causes the loop to think the font is too big. So
     * the loop shrinks the font until the entire line (no breaks) fits
     * within the width (which looks stupid). So we force the child to
     * have the same width as the parent so that line breaks are forced
     * in white-space.
     */
    el.style.width = el.parentNode.offsetWidth + "px";

    // Strangely, on Windows Chrome the parent size changes within this loop
    let parentSize = {
      w: el.parentNode.offsetWidth + 1,
      h: el.parentNode.offsetHeight
    };

    var fontSize = min + (max - min) / 2;
    el.style.fontSize = fontSize + "em";

    // Still check scrollWidth in case there's a very long word (with no white space)
    var fits = el.offsetHeight <= parentSize.h && el.scrollWidth <= parentSize.w;
    if (fits) {
      min = fontSize;
    }else{
      max = fontSize;
    }

    //console.log("fits:"+ fits+" font:" +fontSize+" p:"+parentSize.w+"x"+parentSize.h+" el:"+el.scrollWidth+"x"+el.offsetHeight);

    // within 5% of being too large
    if (max - min < 0.05)
      break;
  }

  el.style.fontSize = min + "em";

};
