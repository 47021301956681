
<template>
  <questions  v-if="league" :league="league"></questions>
</template>

<script>
import axios from 'axios';
export default {
  created() {
    this.reload();
    setInterval(() => this.reload(), 5000);

    return;
    let numQuestionsToAnswer = 14;
    setTimeout(() => {
      let league = JSON.parse(JSON.stringify(this.league));
      league.lastUpdatedTime = Date.now();
      league.lastUpdatedReason = 'A question was answered';
      for (const q of league.questions){
        const questionNumber = league.questions.indexOf(q) + 1;
        for (const a of q.answers){
          a.correct = false;
        }
        if (questionNumber <= numQuestionsToAnswer) {
          q.answers[0].correct = true;
          q.answeredTime = Date.now()+league.questions.indexOf(q);
        }
      }
      precompute(league);
      this.league = league;
    }, 1000);
  },
  data() {
    return {
      league: null,
    };
  },
  components: {
    questions: require('./questions/questions.vue').default,
  },
  methods: {
    reload: async function () {
      const leagueId = this.$route.params.id;
      const oldTime = this.league && this.league.lastUpdatedTime;
      const newTime = parseInt((await axios.get(`/api/scoreboard/get-last-updated-time?id=${leagueId}`)).data);

      if (newTime !== oldTime) {
        let league = (await axios.get(`/api/scoreboard/get-league?id=${leagueId}`)).data;
        precompute(league);
        this.league = league;
      }
    },
  },
};

function precompute(league) {

  for (const question of league.questions) {
    for (const answer of question.answers){
      answer.correct = !!answer.correct;
      answer.guessers = league.users.filter(u => u.guesses[question._id] === answer._id);
    }
    question.correctAnswer = _.find(question.answers, {correct:true});
  }
  league.unansweredQuestions = league.questions.filter(q => {
    return !q.correctAnswer;
  });
  league.answeredQuestions = league.questions.filter(q => {
    return !!q.correctAnswer;
  });
  league.fireQuestions = league.answeredQuestions.length >= 3 && _.takeRight(league.answeredQuestions, 3);
  league.started = league.answeredQuestions.length > 0;
  league.ended = league.answeredQuestions.length === league.questions.length;
  for (const user of league.users) {
    user.rank = 0;
    user.progress = 0;
    user.score = 0;
    user.potential = 0;
    user.gamePotential = 0;
    user.numberCorrect = 0;
    user.ghost = _.isEmpty(user.guesses);
    user.fire = league.fireQuestions && _.every(league.fireQuestions, q => {
      return q.correctAnswer._id === user.guesses[q._id];
    });
    user.answerIds = _.chain(user.guesses).values().filter().value();

    for (const question of league.questions) {
      const guessedAnswerId = user.guesses[question._id];
      const guessedAnswer = _.find(question.answers, {_id:guessedAnswerId});

      if (question.correctAnswer) {
        let isCorrect = question.correctAnswer._id === guessedAnswerId;
        if (isCorrect) {
          user.score += question.correctAnswer.points;
          user.potential += question.correctAnswer.points;
          user.numberCorrect += 1;
        }
      }else{
        if (guessedAnswer) {
          user.potential += guessedAnswer.points;
        }
      }

      if (guessedAnswer) {
        user.gamePotential += guessedAnswer.points;
      }
    }

    user.points = (user.score || 0); //alias
  }

  league.progress = league.answeredQuestions.length / league.questions.length;
  league.rankedUsers = _.chain(league.users).sortBy(u => u.name.toLowerCase()).sortBy(u => -u.score).value();
  if (league.started) {
    league.rankedUsers = league.rankedUsers.filter(u => !u.ghost);
  }
  league.winner = league.ended ? league.rankedUsers[0] : null;
  const highScore = league.rankedUsers[0].score;
  for (const user of league.rankedUsers) {
    const i = league.rankedUsers.indexOf(user);
    user.rank = i + 1;
    user.progress = league.progress * (user.score / (highScore || 1));
  }
  if (league.winner) league.winner.progress = 1.09;
}
</script>
